import { TimelineLite, Power3 } from 'gsap';
import ScrollMagic from './vendor/ScrollMagic.min';
import SplitText from './vendor/SplitText.min';

let controllr;

$('.side-fixed').css('left', '0px');
setTimeout(() => {
  $('.side-fixed').css('left', '');
}, 3500);
function initScrollAnimations() {
  controllr = new ScrollMagic.Controller();

  $('.scroll-reveal').each(function () {
    const element = $(this);

    const scene = new ScrollMagic.Scene({
      triggerHook: 0.75,
      triggerElement: element.get(0),
      reverse: false,
    })
      .on('start', (e) => {
        if (e.scrollDirection !== 'REVERSE') {
          element.trigger('reveal');

          scene.remove();
        }
      })

      // .addIndicators();
      .addTo(controllr);
    // element.css({ opacity: 0 });
  });
  return controllr;
}

function scrollRevealHandler() {
  const tl = new TimelineLite();
  const element = $(this);
  const articles = element.find('article, .hero-accueil-cta, blockquote');
  // var tirets = element.find(".tiret-primary-bottom:before");
  const titres = element
    .find(
      'p.sstitle, .titre, .baseline, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6:not(.entry-tag)'
    )
    .not('.post-list .entry-title');

  const lineDelay = 0.1;
  if (titres.length !== 0) {
    // eslint-disable-next-line no-unused-vars
    const st = new SplitText(titres, {
      // new SplitText(lignes, {
      type: 'lines, words',
      linesClass: 'line',
      wordsClass: 'word',
    });
  }
  const lines = element.find('.line');
  titres.css('visibility', 'visible');

  tl.pause();

  tl.staggerFromTo(
    articles,
    0.8,
    {
      autoAlpha: 0,
      y: 20,
    },
    {
      autoAlpha: 1,
      y: 0,
    },
    0.12
  );

  lines.each(function (index) {
    const line = $(this);
    const words = line.find('.word');
    tl.fromTo(
      words,
      0.8,
      {
        autoAlpha: 0,
        top: line.height(),
      },
      {
        autoAlpha: 1,
        top: -2,
        ease: Power3.easeOut,
      },
      index * lineDelay
    );
  });

  tl.play();
  return false;
}

function resetScrollTriggers() {
  $('.scroll-reveal').on('reveal', scrollRevealHandler);
}

export default function loadPageAnimations() {
  // loadImageMasks();
  controllr = controllr.destroy(true);
  controllr = initScrollAnimations();
  resetScrollTriggers();
}

(function ($) {
  //* Make sure JS is enabled
  document.documentElement.className = 'js';

  $(document).ready(() => {
    controllr = initScrollAnimations();
    resetScrollTriggers();
  });
})(jQuery);
