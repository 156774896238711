jQuery(($) => {
  let lastScrollTop = 0;
  let scrollTop;

  // Add shrink class to site header after 50px

  $(document).on("scroll", () => {
    scrollTop = $(window).scrollTop();

    if (scrollTop > lastScrollTop && scrollTop > 500) {
      $(".site-header").addClass("hidden");
    } else {
      $(".site-header").removeClass("hidden");
    }
    if (
      scrollTop > 500 &&
      scrollTop < $(document).height() - $(window).height() - 250
    ) {
      $(".cta-bar").addClass("visible");
    } else {
      $(".cta-bar").removeClass("visible");
    }
    // if (scrollTop > 0) {
    //   // $(".home .site-header").addClass("bg-white shrink");
    //   $(".site-header.big").removeClass("big");
    // } else {
    //   // $(".home .site-header").removeClass("bg-white");
    //   $(".home .site-header").addClass("big");
    // }
    lastScrollTop = scrollTop;
  });
  $(".menu-toggle").on("click", function() {
    if ($(this).hasClass("activated")) {
      $("html").addClass("overflow-hidden");
    } else {
      $("html").removeClass("overflow-hidden");
    }
  });
});
