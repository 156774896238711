import "isotope-layout/dist/isotope.pkgd.min";

export default {
	init() {
		const $missionsGrid = $(".missions-isotope");
		// JavaScript to be fired on the home page
		$missionsGrid.isotope({
			// options
			itemSelector: ".mission",
			// columnWidth: 200,
			layoutMode: "fitRows",
		});

		$(".mission-filter").on("click", "button", function () {
			const filterValue = $(this).attr("data-filter");
			$missionsGrid.isotope({ filter: filterValue });
		});
	},
	finalize() {},
};
