import Swiper from "swiper";

export default {
	init() {
		// JavaScript to be fired on the home page
		new Swiper(".home-carousel", {
			slidesPerView: 1,
			speed: 700,
			effect: "fade",
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
