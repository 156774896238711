export default {
  init() {
    // JavaScript to be fired on the  page
  },
  finalize() {
    // JavaScript to be fired on the  page, after the init JS
    // jQuery(window).load(function() {
    //   $("section > .row").each(function() {
    //     var e = $(this);
    //     $(window).on("resize", function() {
    //       if (window.innerWidth > 576) {
    //         var featured = e.find(".featured .entry-list");
    //         var older = e.find(".older");
    //         older.height(featured.outerHeight());
    //       }
    //     });
    //   });
    // });
  },
};
