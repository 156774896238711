import autosize from "autosize/dist/autosize.min";
import Swiper from "swiper";

export default {
	init() {
		document.documentElement.style.setProperty(
			"--scrollbar-width",
			window.innerWidth - document.documentElement.clientWidth + "px"
		);

		// eslint-disable-next-line func-names
		$(".btn-spinner").on("click", function () {
			const $this = $(this);
			$this
				.addClass("loading")
				.append(
					"<span class='spinner-border' role='status' aria-hidden='true'></span>"
				);

			// eslint-disable-next-line func-names
			setTimeout(() => {
				$this.removeClass("loading");
				$(".spinner-border").remove();
			}, 4000);
		});

		$(".wpcf7-submit").on("click", function () {
			$(this).addClass("loading");
			$(this)
				.closest(".wpcf7")
				.find(".wpcf7-response-output")
				.hide(0, function () {
					$(this).removeClass(
						"alert alert-danger alert-warning alert-success mt-2"
					);
				});
		});

		$(".wpcf7").on("wpcf7submit", function () {
			$(this).find(".wpcf7-response-output").fadeIn();
			$(this).find(".wpcf7-submit").removeClass("loading");
		});

		$(".wpcf7").on("wpcf7submit", function () {
			$(this)
				.find(".wpcf7-response-output")
				.not(".alert-warning")
				.not(".alert-danger")
				.addClass("alert alert-success mt-2");
		});

		/* Validation Events for changing response CSS classes */

		document.addEventListener(
			"wpcf7invalid",
			(event) => {
				$(event.target)
					.find(".wpcf7-response-output")
					.not(".alert-warning")
					.not(".alert-success")
					.addClass("alert alert-danger mt-2");
			},
			false
		);
		document.addEventListener(
			"wpcf7spam",
			(event) => {
				$(event.target)
					.find(".wpcf7-response-output")
					.not(".alert-danger")
					.not(".alert-success")
					.addClass("alert alert-warning mt-2");
			},
			false
		);
		document.addEventListener(
			"wpcf7mailfailed",
			(event) => {
				$(event.target)
					.find(".wpcf7-response-output")
					.not(".alert-danger")
					.not(".alert-success")
					.addClass("alert alert-warning mt-2");
			},
			false
		);
		document.addEventListener(
			"wpcf7mailsent",
			(event) => {
				$(event.target)
					.find(".wpcf7-response-output")
					.not(".alert-danger")
					.not(".alert-warning")
					.addClass("alert alert-success mt-2");
			},
			false
		);

		// minimalist autoresizable textareas
		autosize($("textarea"));

		$("#video-gallery").lightGallery({
			loadYoutubeThumbnail: true,
			youtubeThumbSize: "default",
			youtubePlayerParams: { modestbranding: 1, showinfo: 0 },
		});

		const nbCard = 4;

		function getDirection() {
			const direction = window.innerWidth <= 360 ? "vertical" : "horizontal";

			return direction;
		}
		// eslint-disable-next-line no-unused-vars
		const swiper = new Swiper(".swiper-container", {
			slidesPerView: nbCard,
			direction: getDirection(),
			grabCursor: true,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			breakpoints: {
				// when window width is >= 0px
				0: {
					slidesPerView: "auto",
				},
				// when window width is >= 576px
				768: {
					slidesPerView: "auto",
				},
				// when window width is >= 768px
				901: {
					slidesPerView: "auto",
				},
				// when window width is >= 991px
				1201: {
					slidesPerView: "auto",
				},
				// when window width is >= 1201px
				1501: {
					slidesPerView: "auto",
				},
			},
			a11y: {
				prevSlideMessage: "Previous slide",
				nextSlideMessage: "Next slide",
			},
			on: {
				resize() {
					if (window.innerWidth >= 640) {
						this.slides.css("width", "");
					}
				},
			},
		});
	},
	finalize() {
		$(".entry-list .entry:not(.associe)").each(function () {
			const e = $(this);
			const et = e.find(".entry-text");
			if (et && et !== undefined) {
				const eth = et.outerHeight();
				const eh = e.find(".entry-header");
				const ehh = eh.outerHeight();
				et.height(ehh);
				// et.css("height", `${ehh}px`);
				// et.get(0).style.height = `${ehh}px`;

				e.on({
					mouseenter() {
						et.height(eth);
					},
					mouseleave() {
						if (eh.outerHeight() !== undefined) {
							et.height(ehh);
						}
					},
				});
			}
		});
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
