export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    $(".social-share").jsSocials({
      showLabel: false,
      showCount: false,
      shareIn: "popup",
      shares: [
        "twitter",
        "linkedin",
        {
          share: "telegram",
          label: "Telegram",
          logo: "fa fa-paper-plane",
        },
        "email",
      ],
    });
  },
};
