import Barba from "barba.js/dist/barba";
import loadPageAnimations from "./animation";

export default function barbaInit(routes) {
	Barba.Pjax.Dom.containerClass = "pjax-container";
	Barba.Pjax.Dom.wrapperId = "pjax-wrapper";
	let spinnerTimeOut;
	// Create a global variable to store the bodyClasses
	let bodyClasses;
	// Blacklist all WordPress Links (e.g. for adminbar)
	function addBlacklistClass() {
		$("a").each(function () {
			if (
				this.href.indexOf("/wp-admin/") !== -1 ||
				this.href.indexOf("/wp-login.php") !== -1
			) {
				$(this).addClass("no-barba").addClass("wp-link");
			}
		});
	}

	// Set blacklist links
	addBlacklistClass();

	// Fire Barba.js
	Barba.Pjax.start();
	Barba.Prefetch.init();

	Barba.Dispatcher.on(
		"newPageReady",
		(currentStatus, prevStatus, container, newPageRawHTML) => {
			const path = currentStatus.url
				.split(window.location.origin)[1]
				.substring(1); // get path of current page

			// "notbody" see: https://github.com/luruke/barba.js/issues/51#issuecomment-241417316
			const response = newPageRawHTML.replace(
				/(<\/?)body( .+?)?>/gi,
				"$1notbody$2>",
				newPageRawHTML
			);
			bodyClasses = $(response).filter("notbody").attr("class");
			// $("body").attr("class", bodyClasses);
			$(".menu-item").removeClass("active");
			$(`a[href$="${path}"]`).parent(".menu-item").addClass("active"); // add CSS class to current .navigation__link
			// $(".site-header").removeClass("big");
			// if ($(response).hasClass("home")) {
			//   $(".site-header")
			//     .addClass("big")
			//     .removeClass("bg-white shadow-sm");
			// } else {
			//   $(".site-header").addClass("bg-white");
			// }
			if (typeof ga === "function") {
				ga("send", "pageview", window.location.pathname);
			}
			if (window.ga && document.location.hostname !== "localhost") {
				ga = undefined;
				(function (w, d, s, l, i) {
					// eslint-disable-next-line no-param-reassign
					w[l] = w[l] || [];
					w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
					const f = d.getElementsByTagName(s)[0];
					const j = d.createElement(s);
					const dl = l !== "dataLayer" ? `&l=${l}` : "";
					j.async = true;
					j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
					f.parentNode.insertBefore(j, f);
				})(window, document, "script", "dataLayer", "GTM-W4ND7N4");
			}
		}
	);

	Barba.Dispatcher.on("transitionCompleted", () => {
		// Set new classes from #af-classes to body
		// $("body").attr("class", $("#body-classes").attr("class"));

		if (typeof DSObserve === "function") {
			DSObserve();
		}

		loadPageAnimations();

		$("section > .row").each(function () {
			const e = $(this);
			const featured = e.find(".featured .entry-list");
			const older = e.find(".older");
			older.height(featured.outerHeight());
		});

		// $(".entry-list .entry:not(.associe)").hover(
		// 	function () {
		// 		const e = $(this);
		// 		const et = e.find(".entry-text");
		// 		const eh = e.find(".entry-header");
		// 		// var ecb = e.find(".entry-header:before");
		// 		const ec = e.find(".entry-content");
		// 		const h = eh.outerHeight() + ec.outerHeight();
		// 		et.height(h);
		// 	},
		// 	function () {
		// 		const e = $(this);
		// 		const et = e.find(".entry-text");
		// 		const eh = e.find(".entry-header");
		// 		et.height(eh.outerHeight());
		// 	}
		// );
		// Fire routes again after new content loaded
		routes.loadEvents();
	});

	const FadeTransition = Barba.BaseTransition.extend({
		start() {
			/**
			 * This function is automatically called as soon the Transition starts
			 * this.newContainerLoading is a Promise for the loading of the new container
			 * (Barba.js also comes with an handy Promise polyfill!)
			 */

			if ($(".menu-toggle").hasClass("activated")) {
				$(".menu-toggle").click();
			}
			spinnerTimeOut = setTimeout(() => {
				$("#spinner").fadeIn();
			}, 1000);
			// As soon the loading is finished and the old page is faded out, let's fade the new page
			Promise.all([this.newContainerLoading, this.fadeOut()]).then(
				this.fadeIn.bind(this)
			);
		},

		fadeOut() {
			/**
			 * this.oldContainer is the HTMLElement of the old Container
			 */

			return $(this.oldContainer).animate({ opacity: 0 }).promise();
		},

		fadeIn() {
			/**
			 * this.newContainer is the HTMLElement of the new Container
			 * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
			 * Please note, newContainer is available just after newContainerLoading is resolved!
			 */

			const _this = this;
			const $el = $(this.newContainer);

			$(this.oldContainer).hide();

			$el.css({
				visibility: "visible",
				opacity: 0,
			});
			// Apply the classes to the current body
			$("body").attr("class", bodyClasses);
			$(window).scrollTop(0);
			clearTimeout(spinnerTimeOut);
			$("#spinner").fadeOut();
			// addthis.layers.refresh();
			$el.animate({ opacity: 1 }, 400, () => {
				/**
				 * Do not forget to call .done() as soon your transition is finished!
				 * .done() will automatically remove from the DOM the old Container
				 */

				_this.done();
			});
		},
	});

	/**
	 * Next step, you have to tell Barba to use the new Transition
	 */

	Barba.Pjax.getTransition = function () {
		/**
		 * Here you can use your own logic!
		 * For example you can use different Transition based on the current page or link...
		 */

		return FadeTransition;
	};
}
