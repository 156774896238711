// import external dependencies
import "jquery";

// Import everything from autoload
import "./autoload/_bootstrap.js";

import "./vendor/responsive-menu";
import "./vendor/jssocials.custom";
import "./site-header";
// import then needed Font Awesome functionality
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import "lightgallery";
import "lg-thumbnail";
import "lg-video";

import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faPinterestP } from "@fortawesome/free-brands-svg-icons/faPinterestP";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";

import barbaInit from "./barba-init";
// import local dependencies
import Router from "./util/Router";
import common from "./routes/common";
import home from "./routes/home";
import aboutUs from "./routes/about";
import publications from "./routes/publications";
import clients from "./routes/clients";
import singlePost from "./routes/singlePost";

/** Populate Router instance with DOM routes */
const routes = new Router({
	// All pages
	common,

	// Home page
	home,

	// About Us page, note the change from about-us to aboutUs.
	aboutUs,
	singlePost,

	publications,
	clients,
});

// add the imported icons to the library
library.add(
	faFacebookF,
	faTwitter,
	faPinterestP,
	faYoutube,
	faLinkedinIn,
	faEnvelope,
	faPaperPlane
);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

// Load Events
jQuery(document).ready(() => {
	routes.loadEvents();
	barbaInit(routes);
	// eslint-disable-next-line no-console
	console.log("_JS_READY");
});
